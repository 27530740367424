import ky from "ky";
import React, { useEffect } from "react";
const Test = () => {
  useEffect(() => {
    testAPI();
  }, []);

  const testAPI = async () => {
    const data = {
      page: 1,
      size: 20,
    };
    const res = await ky
      .post("/api/v1/formMail_ad/allJobsiteList", { json: data })
      .json();
    console.log("1 ", res);
  };

  return (
    <>
      <div className="w-full max-w-[700px] mx-auto h-fit overflow-auto bg-white">
        <img
          src="https://api.inssain.co.kr/redirect?url=http://millinienimg.godohosting.com/c91113592_3123.gif"
          alt="test"
        />
      </div>
    </>
  );
};

export default Test;
