import { useState, useRef, useEffect } from "react";
import { storage } from "../firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import imageCompression from "browser-image-compression";
import dayjs from "dayjs";

function InputPhoto(props) {
  const photoRef = useRef();
  const [uploaded, setUploaded] = useState(false);

  useEffect(() => {
    if (props.photo) {
      setUploaded(true);
    }
  }, [props.photo]);

  // 🔹 이미지 크기 조정 함수 (너비 400px, 높이는 비율 유지)
  const resizeImage = file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = event => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // 📌 너비를 400px로 고정하고 높이를 자동으로 설정
          const MAX_WIDTH = 400;
          const scale = MAX_WIDTH / img.width;
          canvas.width = MAX_WIDTH;
          canvas.height = img.height * scale;

          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Blob 변환
          canvas.toBlob(
            blob => {
              resolve(new File([blob], file.name, { type: "image/jpeg" }));
            },
            "image/jpeg",
            0.7 // 70% 품질 유지
          );
        };
      };
    });
  };

  // 🔹 이미지 압축 후 업로드
  const uploadImage = async file => {
    if (!file) return;

    try {
      const resizedFile = await resizeImage(file);

      // ✅ 압축 옵션 (용량을 1MB 이하로 줄임)
      const options = {
        maxSizeMB: 1, // 최대 용량 1MB
        useWebWorker: true, // 웹 워커 사용
      };

      const compressedFile = await imageCompression(resizedFile, options);
      console.log("압축된 파일:", compressedFile);

      let date = dayjs(new Date()).format("YYMMDDhhmmss");
      let date2 = dayjs(new Date()).format("YYMMDD");
      const storagePath = `photo/newsurvey/${date2}/${props.name + "_" + date}`;
      const storageRef = ref(storage, storagePath);
      const uploadTask = uploadBytesResumable(storageRef, compressedFile);

      props.setPhotoTask(storagePath);

      uploadTask.on(
        "state_changed",
        snapshot => {
          setUploaded(true);
        },
        err => console.log(err),
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          props.setPhoto(url);
        }
      );
    } catch (error) {
      console.error("이미지 압축 실패:", error);
    }
  };

  const photoUpload = () => {
    if (props.name === "") {
      return alert("성함을 입력해 주세요");
    }
    photoRef.current.click();
  };

  const photoDelete = () => {
    let deleteIt = window.confirm(
      "삭제하면 다시 업로드 하셔야 합니다, 삭제할까요?"
    );
    if (deleteIt) {
      const deleteRef = ref(storage, props.photo);
      deleteObject(deleteRef)
        .then(() => {
          props.setPhoto("");
          props.setPhotoTask("");
          setUploaded(false);
          photoRef.current.value = "";
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  return (
    <div id="inputPhoto">
      <div className="p-2 bg-gray-200 font-medium">
        <h2>
          <label htmlFor="photo">
            지원자님의 가장 잘 나온 사진을 올려주세요
          </label>
        </h2>
      </div>
      <div className="p-2 pb-3 bg-gray-100">
        <input
          type="file"
          accept="image/*"
          ref={photoRef}
          id="photo"
          name="photo"
          className="hidden"
          onChange={e => uploadImage(e.target.files[0])}
          disabled={uploaded}
        />
        <button
          onClick={photoUpload}
          className="block transition duration-150 rounded bg-blue-500 p-2 text-white font-medium w-full hover:bg-blue-900"
        >
          업로드
        </button>
        {uploaded && (
          <div className="text-center p-2 my-2 bg-white">
            <h3 className="text-left p-2 mb-2 font-medium">이미지 미리보기</h3>
            <img
              src={props.photo}
              alt="업로드중입니다"
              className="max-h-96 ml-2"
            />
            <button
              onClick={photoDelete}
              className="block transition duration-150 rounded bg-pink-500 p-2 mt-4 text-white font-medium w-full hover:bg-pink-900"
            >
              다시 올리기
              <br className="lg:hidden" />
              <small className="lg:ml-2">(기존 사진은 삭제됩니다)</small>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default InputPhoto;
