import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";

function SurveyIn() {
  const [isErr, setIsErr] = useState(false);
  const navi = useNavigate();
  const { applyId } = useParams();
  useEffect(() => {
    if (applyId) {
      moveSurvey(applyId);
    }
    //eslint-disable-next-line
  }, [applyId]);
  const moveSurvey = async applyId => {
    try {
      const docRef = doc(db, "shortSurvey", applyId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log(data);
        if (data.applyId && data.aid && data.gender) {
          navi(`/svrenew/${data.applyId}/${data.aid}/${data.gender}/new`);
        } else {
          setIsErr(true);
        }
      } else {
        setIsErr(true);
      }
    } catch (error) {
      setIsErr(true);
    }
  };
  return (
    <div className="w-fit h-fit fixed top-5 left-1/2 -translate-x-1/2 bg-white p-4 rounded-lg">
      {!isErr
        ? "잠시만 기다려 주세요..."
        : "잘못된 경로로 접근하셨습니다. 관리자에게 문의해주세요"}
    </div>
  );
}

export default SurveyIn;
